<template>
  <div class="index_wrapper">
    <div class="title">订单管理</div>
    <!-- 状态选择 -->
    <div style="margin-bottom: 1rem">
      <a-radio-group
        :default-value="orderStatusString"
        button-style="solid"
        @change="orderType"
      >
        <a-radio-button value="ALL"> 全部 </a-radio-button>
        <a-radio-button value="WAITING_PAY"> 待支付 </a-radio-button>
        <a-radio-button value="WAITING_DELIVERY"> 待收货 </a-radio-button>
        <a-radio-button value="RECEIVED"> 已收货 </a-radio-button>
        <a-radio-button value="DONE"> 已完成 </a-radio-button>
        <a-radio-button value="CANCELED"> 已取消 </a-radio-button>
        <a-radio-button value="WAITING_REFUND"> 待退款 </a-radio-button>
        <a-radio-button value="REFUNDED"> 已退款 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="content">
      <div class="action">
        <a-form-item
          label="酒店名称"
          style="display: flex; justify-content: flex-end"
        >
          <hotelSelect
            :value="value"
            @selectChange="getHotelValue"
            v-if="testFlag"
          ></hotelSelect>
        </a-form-item>
        <div>
          <span>下单时间：</span>
          <a-range-picker @change="checkDate" />
        </div>
        <div>
          <a-input-search
            v-model="searchKey"
            placeholder="请输入订单、姓名、电话"
            enter-button="搜索"
            @search="onSearch"
          />
        </div>
      </div>
      <a-table
        bordered
        :data-source="orderData"
        :pagination="false"
        rowKey="orderNo"
      >
        <a-table-column key="orderNo" title="订单号" data-index="orderNo" />
        <a-table-column
          key="orderDetailList"
          title="商品"
          data-index="orderDetailList"
        >
          <template slot-scope="record">
            <div>
              <span>
                {{ record[0].goodsName }}
              </span>
              <span class="goodsDet" @click="goodsDetail(record)">查看</span>
            </div>
          </template>
        </a-table-column>
        <a-table-column
          key="totalPrice"
          title="订单金额"
          data-index="totalPrice"
        >
          <template slot-scope="record">
            {{ record }}元
          </template></a-table-column
        >
        <a-table-column key="userName" title="姓名" data-index="userName" />
        <a-table-column key="mobile" title="电话号码" data-index="mobile" />
        <a-table-column
          key="deliveryMethodString"
          title="取件方式"
          data-index="deliveryMethodString"
          width="100px"
        >
          <template slot-scope="text, record">
            <span v-if="text === 'PICK_UP'"> 自提 </span>
            <span v-if="text === 'SEND_ROOM'">
              送到客房：{{ JSON.parse(record.deliveryAddress).roomNumber }}
            </span>
            <span v-if="text !== 'SEND_ROOM' && text !== 'PICK_UP'">--</span>
          </template>
        </a-table-column>
        <a-table-column key="orderTime" title="下单时间" data-index="orderTime">
          <template slot-scope="record">
            <span>
              {{ record | formatDate }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="remark" title="备注" data-index="remark">
          <template slot-scope="record">
            <span>
              {{ record ? record : '--' }}
            </span>
          </template>
        </a-table-column>
        <a-table-column
          key="orderStatusString"
          title="状态"
          data-index="orderStatusString"
        >
          <template slot-scope="record">
            <span>
              {{ record | orderStatus }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <!-- 待支付 -->
              <span v-if="record.orderStatusString === 'WAITING_PAY'">
                <a @click="cancelOrder(record)">取消订单</a>
                <a-divider type="vertical" />
                <a @click="edit(record)">编辑</a>
              </span>
              <!-- 待收货 -->
              <span v-if="record.orderStatusString === 'WAITING_DELIVERY'">
                <a @click="sureGoods(record)">确认收货</a>
                <a-divider type="vertical" />
                <a @click="edit(record, 'insert')">编辑</a>
              </span>
              <span v-if="record.orderStatusString === 'RECEIVED'">
                <a>--</a>
              </span>
              <span v-if="record.orderStatusString === 'DONE'">
                <a>--</a>
              </span>
              <span v-if="record.orderStatusString === 'CANCELED'">
                <a>--</a>
              </span>
              <!-- 待退款 -->
              <span v-if="record.orderStatusString === 'WAITING_REFUND'">
                <a @click="waitingRefund(record)">确认退款</a>
                <a-divider type="vertical" />
                <a @click="edit(record, 'insert')">编辑</a>
              </span>
              <span v-if="record.orderStatusString === 'REFUNDED'">
                <a>--</a>
              </span>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 商品详情 -->
      <a-modal v-model="goodsDetailVis" title="详情" @ok="handleSure">
        <a-table
          bordered
          :data-source="orderDetailList"
          :pagination="false"
          rowKey="id"
        >
          <a-table-column
            key="goodsName"
            title="商品名称"
            data-index="goodsName"
          />
          <a-table-column key="costPrice" title="成本价" data-index="costPrice">
            <template slot-scope="record">
              {{ record ? record.toFixed(2) : '--' }}
            </template>
          </a-table-column>
          <a-table-column
            key="basePrice"
            title="商品原价"
            data-index="basePrice"
          >
            <template slot-scope="record">
              {{ record.toFixed(2) }}
            </template>
          </a-table-column>
          <a-table-column
            key="goodsPrice"
            title="商品折扣价"
            data-index="goodsPrice"
          >
            <template slot-scope="record">
              {{ record.toFixed(2) }}
            </template>
          </a-table-column>
          <a-table-column
            key="goodsNum"
            title="商品数量"
            data-index="goodsNum"
          />
        </a-table>
      </a-modal>
      <!-- 分页 -->
      <a-pagination
        :page-size-options="pageSizeOptions"
        :showSizeChanger="true"
        show-quick-jumper
        :current="page"
        :total="total"
        :show-total="(total) => `共 ${total} 条数据`"
        :pageSize="limit"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getBlocOrderList,
  cancelBlocOrderList,
  sureBlocOrderList,
  sureWaitingRefund
} from '../../../api/order.js'
import moment from 'moment'
import local from '../../../utils/local.js'
export default {
  inject: ['reload'],
  props: {
    search: {
      type: Object
    }
  },
  data() {
    const self = this
    return {
      value: '',
      orderDetailList: [],
      blocList: [],
      selectParentId: '',
      orderData: [],
      // 商品详情模态框
      goodsDetailVis: false,
      // 搜索参数
      searchKey: '',
      // 时间区间
      startTime: '',
      endTime: '',
      // 分页参数
      pageSizeOptions: ['5', '10', '20', '30', '50'],
      page: 1,
      limit: 10,
      total: 20,
      params: {},
      testFlag: false,
      orderStatusString: 'ALL' // 订单状态
    }
  },
  created() {
    this.params = {
      limit: this.limit,
      page: this.page,
      parentId: this.selectParentId,
      searchKey: this.searchKey
    }
    this.orderStatusString = local.get('orderStatusString')
    if (this.orderStatusString === null) {
      this.orderStatusString = 'ALL'
    }
    this.params.orderStatusString = this.orderStatusString
    if (this.$store.state.orderStatusString !== null) {
      this.orderStatusString = this.$store.state.orderStatusString
    }
    this.hotelList = this.$store.state.hotelArray
    if (this.$store.state.hotelSelectParentId !== null) {
      this.selectParentId = this.$store.state.hotelSelectParentId
    } else {
      this.selectParentId = this.hotelList[0].hotelId
    }
    this.selectParentId = local.get('hotelId')
    if (this.$route.query.orderData !== undefined) {
      this.orderStatusString = this.$route.query.orderData.orderStatusString
      this.searchKey = this.$route.query.orderData.orderNo
    } else {
      var orderData = this.$route.query.id
      if (orderData) {
        var orderNo = orderData.split('N')[0]
        var hotel = orderData.split('N')[1]
        local.set('hotelId', hotel)
        this.searchKey = orderNo
        this.selectParentId = hotel
        // this.orderStatusString = 'WAITING_DELIVERY'
        this.orderStatusString = 'ALL'
        this.params.searchKey = orderNo
        this.params.orderStatusString = this.orderStatusString
        this.params.parentId = hotel
      }
    }
    if (this.orderStatusString === 'ALL') {
      this.params.orderStatusString = ''
    } else {
      this.params.orderStatusString = this.orderStatusString
    }
    if (this.params.orderStatusString === undefined) {
      this.orderStatusString = 'ALL'
    }
    console.log(this.params)
    // if (this.params)
    const params = { ...this.params }
    console.log(params)
    if (!params.parentId) {
      params.parentId = this.selectParentId
    }
    this.getOrderList(params)
    this.testFlag = true
  },
  methods: {
    // 获取订单列表
    async getOrderList(params) {
      const data = await getBlocOrderList(params)
      this.total = data.data.total
      this.orderData = data.data.records
    },
    getHotelValue(v) {
      this.selectParentId = v
      this.params.parentId = v
      // this.getOrderList(this.params)
    },
    async checkDate(date, dateString) {
      this.startTime = moment(dateString[0]).format('YYYY-MM-DD')
      this.endTime = moment(dateString[1]).format('YYYY-MM-DD')
      let startTime = new Date(this.startTime)
      let endTime = new Date(this.endTime)
      startTime = startTime.getTime()
      endTime = endTime.getTime() + (24 * 60 * 60 * 1000 - 1)
      this.params.startTime = startTime
      this.params.endTime = endTime
      this.params.page = 1
      this.page = 1
      const data = await getBlocOrderList(this.params)
      this.total = data.data.total
      this.orderData = data.data.records
    },
    // 订单状态选择
    async orderType(v) {
      local.set('orderStatusString', v.target.value)
      this.params.orderStatusString = v.target.value
      this.orderStatusString = v.target.value
      if (this.orderStatusString === 'ALL') {
        this.params.orderStatusString = ''
      } else {
        this.params.orderStatusString = this.orderStatusString
      }
      this.params.page = 1
      this.page = 1
      this.getOrderList(this.params)
    },
    // 确认退款
    waitingRefund(e) {
      this.refoundOrderNo = e.orderNo
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '订单是否确认退款？',
        okType: 'danger',
        async onOk() {
          const data = await sureWaitingRefund({
            orderNo: e.orderNo,
            tenantId: local.get('blocId')
          })
          // data.code === 0 ? self.reload() : this.$message.error(data.message)
          if (data.code === 0) {
            self.params.orderStatusString = local.get('orderStatusString')
            self.$message.success(data.message)
            self.getOrderList(self.params)
          } else {
            self.$message.error(data.message)
          }
        }
      })
    },
    // 确认收货
    sureGoods(e) {
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '是否确认收货？',
        okType: 'danger',
        async onOk() {
          const data = await sureBlocOrderList({
            orderNo: e.orderNo
          })
          if (data.code === 0) {
            self.getOrderList(self.params)
          } else {
            self.$message.error(data.message)
          }
        }
      })
    },
    // 取消订单
    cancelOrder(e) {
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '是否确认取消订单？',
        okType: 'danger',
        async onOk() {
          const data = await cancelBlocOrderList({
            orderNo: e.orderNo
          })
          // data.code === 0 ? self.reload() : this.$message.error(data.message)
          if (data.code === 0) {
            self.getOrderList(self.params)
          } else {
            self.$message.error(data.message)
          }
        }
      })
    },
    // 商品详情
    goodsDetail(e) {
      this.orderDetailList = e
      this.goodsDetailVis = true
    },
    handleSure() {
      this.goodsDetailVis = false
    },
    // 分页
    async onChangePage(pageNumber) {
      this.page = pageNumber
      this.params.page = pageNumber
      const data = await getBlocOrderList(this.params)
      this.total = data.data.total
      this.orderData = data.data.records
    },
    onShowSizeChange(pageNumber, limit) {
      this.limit = limit
      this.params.limit = limit
      this.params.page = 1
      this.page = 1
      this.getOrderList(this.params)
    },
    selectChange(v) {
      this.params.selectParentId = v
      this.getOrderList(this.params)
    },
    // 编辑
    edit(r, insert) {
      this.$store.state.hotelSelectParentId = this.selectParentId
      this.$router.push({
        name: 'action',
        params: { info: r, isInsert: insert ? 1 : 0 }
      })
    },
    orderLog() {},
    // 搜索
    async onSearch(value) {
      this.searchKey = value
      // const a = this.$options.filters.formatDate(this.startTime)
      let startTime = new Date(this.startTime)
      let endTime = new Date(this.endTime)
      startTime = startTime.getTime()
      endTime = endTime.getTime()
      this.params.searchKey = this.searchKey
      this.params.startTime = startTime
      this.params.endTime = endTime
      const data = await getBlocOrderList(this.params)
      this.total = data.data.total
      this.orderData = data.data.records
    }
  },
  // 时间戳转时间
  filters: {
    formatDate: function(value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
    },
    orderStatus(v) {
      switch (v) {
        case 'WAITING_PAY':
          return '待支付'
        case 'WAITING_DELIVERY':
          return '待收货'
        case 'RECEIVED':
          return '已收货'
        case 'DONE':
          return '已完成'
        case 'CANCELED':
          return '已取消'
        case 'WAITING_REFUND':
          return '待退款'
        case 'REFUNDED':
          return '已退款'
        default:
          return '异常'
      }
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
/deep/.ant-modal {
  width: 670px;
}
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 100%;
  .goodsDet {
    color: rgba(65, 150, 255, 1);
    cursor: pointer;
  }
  .title {
    font-size: 18px;
    padding-bottom: 1rem;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    height: 90%;
    .action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1.4rem 1rem;
      .ant-form-item {
        margin: 0;
      }
      .ant-select {
        height: 32px;
        padding-right: 1rem;
      }
    }
  }
}

.ant-input-group-wrapper {
  width: 260px !important;
}
.ant-calendar-picker {
  width: 200px;
  margin-right: 5px !important;
}
</style>
