<template>
  <div class="index_wrapper">
    <div class="title">商城配置</div>
    <div class="content">
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <a-form-item label="酒店名称">
          <hotelSelect @selectChange="getHotelValue"></hotelSelect>
        </a-form-item>
      </div>
      <a-table bordered :data-source="data" :pagination="false" rowKey="id">
        <a-table-column
          key="qrCodeUrl"
          title="小程序二维码"
          data-index="qrCodeUrl"
        >
          <template slot-scope="record">
            <a :href="record" target="_blank">
              <img :src="record" style="width: 40px" />
            </a>
          </template>
        </a-table-column>
        <a-table-column
          key="parentName"
          title="酒店名称"
          data-index="parentName"
        />
        <a-table-column key="mallName" title="商城名称" data-index="mallName" />
        <a-table-column
          key="serviceTel"
          title="客服电话"
          data-index="serviceTel"
        >
          <template slot-scope="record">
            <span>
              {{ record !== null ? record[0] : '' }}
            </span>
          </template>
        </a-table-column>
        <a-table-column
          key="pickupAddress"
          title="自提说明"
          data-index="pickupAddress"
        >
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="edit(record)">编辑</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        show-quick-jumper
        :page-size-options="pageSizeOptions"
        :showSizeChanger="true"
        :current="page"
        :total="total"
        :show-total="(total) => `共 ${total} 条数据`"
        :pageSize="limit"
        @change="onChangePage"
        @showSizeChange="onShowSizeChange"
      />
      <!-- 编辑 -->
      <a-modal
        v-model="visible"
        title="编辑"
        okText="确定"
        cancelText="取消"
        @ok="onSubmit"
      >
        <a-form-model :model="editData" ref="ruleForm" :rules="rules">
          <div class="form_model">
            <a-form-model-item label="集团名称">
              {{ editData.parentName }}
            </a-form-model-item>
            <a-form-model-item label="商城名称" prop="mallName">
              <a-input
                v-model="editData.mallName"
                placeholder="必填"
                :maxLength="8"
                ref="nameInputValue"
                @keyup="nameInpNum"
              />
              <span class="inpNum">{{ inpNum }}/8</span>
            </a-form-model-item>
            <a-form-model-item
              label="自提说明"
              prop="pickupAddress"
              style="margin-top: 10px"
            >
              <a-input v-model="editData.pickupAddress" type="textarea" />
            </a-form-model-item>
            <a-form-model-item label="客服电话">
              <span style="font-size: 12px; color: rgba(153, 153, 153, 1)"
                >最多可添加五个客服电话</span
              >
              <div
                class="phone"
                v-for="(item, i) in editData.serviceTel"
                :key="i"
              >
                <a-input
                  v-model="editData.serviceTel[i]"
                  placeholder="必填"
                  style="width: 92%; margin-bottom: 15px"
                  @blur="checkPhone(editData.serviceTel[i], i)"
                />
                <!-- <div class="desc" v-if="i">11</div> -->
                <img
                  src="../../../assets/imgs/reduce@1x (1).png"
                  class="reduce"
                  @click="reduce(i)"
                  v-if="i !== 0"
                />
                <img
                  src="../../../assets/imgs/add@1x.png"
                  class="add"
                  @click="add(i)"
                  v-if="i === editData.serviceTel.length - 1 && i < 4"
                />
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getBlocList, updateBlocList } from '../../../api/blocImpower.js'
export default {
  inject: ['reload'],
  data() {
    return {
      value: 'all',
      hotelList: [],
      data: [],
      Id: [],
      editData: {},
      rules: {
        mallName: [
          {
            pattern: /^[^\s]*$/,
            required: true,
            message: '请输入商城名称',
            trigger: 'blur'
          },
          {
            min: 0,
            max: 30,
            message: '长度限制30个字符',
            trigger: 'blur'
          }
        ],
        pickupAddress: [
          {
            required: true,
            message: '请输入自提说明',
            trigger: 'blur'
          }
        ],
        serviceTel: [
          {
            pattern: new RegExp(
              /^(((\d{3,4}-)?[0-9]{7,8})|(1(3|4|5|6|7|8|9)\d{9}))$/,
              'g'
            ),
            message: '请输入客服电话',
            trigger: 'blur'
          }
        ]
      },
      visible: false,
      inpNum: 0,
      // 分页参数
      page: 1,
      limit: 10,
      total: 0,
      pageSizeOptions: ['5', '10', '20', '30', '50'],
      flag: false,
      parentIdList: [],
      hotelArray: [],
      params: {}
    }
  },
  created() {
    this.$store.state.hotelArray.forEach(item => {
      this.hotelArray.push(item.hotelId)
    })
    this.parentIdList = this.$store.state.hotelValue
      ? [this.$store.state.hotelValue]
      : this.hotelArray
    this.params = {
      parentIdList: this.parentIdList,
      limit: this.limit,
      page: this.page,
      mallTypeString: 'HOTEL'
    }
    this.getList(this.params)
  },
  methods: {
    // 获取集团授权列表
    async getList(params) {
      const data = await getBlocList(params)
      if (data.code === 0) {
        this.data = data.data.records
        this.total = data.data.total
      }
    },
    getHotelValue(v) {
      v = v ? [v] : this.hotelArray
      this.params.parentIdList = v
      // this.getList(this.params)
    },
    // 校验客服电话规则
    checkPhone(value) {
      if (value === '') {
        this.$message.error('请输入联系人电话')
      } else {
        let regPone = null
        const mobile = /^1(3|4|5|6|7|8|9)\d{9}$/ // 最新16手机正则
        const tel = /^(0[0-9]{2,3}-)([2-9][0-9]{4,7})+(-[0-9]{1,4})?$/ // 座机
        const n = /^(400)-(\d{3})-(\d{4})$/
        if (value.charAt(0) === '0') {
          // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
          regPone = tel
        } else if (value.charAt(0) === '4') {
          regPone = n
        } else {
          regPone = mobile
        }
        if (!regPone.test(value)) {
          this.flag = false
          return this.$message.error(
            "请填写联系人电话(座机格式'区号-座机号码'/400开头的客服电话)"
          )
        }
        this.flag = true
        this.$message.success('格式正确')
      }
    },
    // getHotelValue(v) {
    //   this.value = v
    //   if (v === 'all') {
    //     const temp = []
    //     this.hotelList.forEach(item => {
    //       item.code !== 'all' ? temp.push(item.hotelId) : ''
    //     })
    //     this.getList(temp, this.limit, this.page)
    //   } else {
    //     const temp = [v]
    //     this.getList(temp, this.limit, this.page)
    //   }
    // },

    // 分页
    onChangePage(pageNumber) {
      this.page = pageNumber
      if (this.value === 'all') {
        const temp = []
        this.hotelList.forEach(item => {
          item.code !== 'all' ? temp.push(item.hotelId) : ''
        })
        this.getList(temp, this.limit, this.page)
      } else {
        this.getList(this.value, this.limit, this.page)
      }
    },
    onShowSizeChange(pageNumber, limit) {
      this.limit = limit
      this.page = 1
      if (this.value === 'all') {
        const temp = []
        this.hotelList.forEach(item => {
          item.code !== 'all' ? temp.push(item.hotelId) : ''
        })
        this.getList(temp, this.limit, this.page)
      } else {
        this.getList(this.value, this.limit, this.page)
      }
    },
    onChange(checked) {
      // console.log(`a-switch to ${checked}`)
    },
    // 输入框文字数量计算
    nameInpNum() {
      this.inpNum =
        this.$refs.nameInputValue.value === null
          ? 0
          : this.$refs.nameInputValue.value.length
    },
    // 增加输入框
    add(i) {
      this.editData.serviceTel.push('')
    },
    // 删除输入框
    reduce(i) {
      this.editData.serviceTel.splice(i, 1)
    },
    // 编辑
    edit(r) {
      this.visible = true
      this.flag = true
      this.editData = JSON.parse(JSON.stringify(r))
      this.editData.serviceTel === null ? (this.editData.serviceTel = ['']) : ''
      if (this.editData.serviceTel.length === 0) {
        this.editData.serviceTel.push('')
      }
      this.inpNum = r.mallName ? r.mallName.length : 0
    },
    onSubmit() {
      if (this.editData.serviceTel.length === 1 && this.editData.serviceTel[0] === '') {
        this.flag = true
      } else {
        if (this.editData.serviceTel.length > 0) {
          for (var j = 0; j < this.editData.serviceTel.length; j++) {
            if (this.editData.serviceTel[j] === '') {
              this.editData.serviceTel.splice(j, 1)
            }
          }
        }
        for (var i = 0; i < this.editData.serviceTel.length; i++) {
          var item = this.editData.serviceTel[i]
          let regPone = null
          const mobile = /^1(3|4|5|6|7|8|9)\d{9}$/ // 最新16手机正则
          const tel = /^(0[0-9]{2,3}-)([2-9][0-9]{4,7})+(-[0-9]{1,4})?$/ // 座机
          if (item.charAt(0) === '0') {
          // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
            regPone = tel
          } else {
            regPone = mobile
          }
          if (!regPone.test(item)) {
            this.flag = false
            break
          }
          this.flag = true
        }
      }
      this.$refs.ruleForm.validate(async valid => {
        const _this = this
        if (valid && this.flag) {
          const data = await updateBlocList(this.editData)
          if (data.code === 0) {
            const temp = [this.value]
            this.getList(this.params)
            this.$message.success(data.message)
            this.visible = false
          } else {
            this.$message.error(data.message)
          }
        } else if (!this.flag) {
          // this.$message.error("请填写联系人电话(座机格式'区号-座机号码')")
          return false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 90%;
  .title {
    font-size: 18px;
    padding-bottom: 1.8rem;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    height: 100%;
    .ant-form-item {
      display: flex;
      // width: 24%;
      padding: 1.6rem 0;
      margin: 0;
    }
    .ant-select {
      height: 32px;
      padding-right: 1rem;
    }
  }
}
/deep/.ant-modal-content {
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}
.phone {
  position: relative;
  .reduce {
    position: absolute;
    right: 2%;
    bottom: 40%;
    cursor: pointer;
  }
  .add {
    position: absolute;
    bottom: 40%;
    right: -4%;
    cursor: pointer;
  }
}
.form_model .ant-form-item {
  margin: 0;
}
.desc {
  position: absolute;
  left: 0.6rem;
  top: 2.5rem;
}
</style>
