<template>
  <div class="index_wrapper">
    <div class="title">商城授权</div>
    <div class="content">
      <div style="display: flex; justify-content: flex-end; padding: 1.6rem 0">
        <a-form-item label="集团名称" style="display: flex">
          <blocSelect @selectChange="getBlocValue" :show="isAdmin">
          </blocSelect>
          <hotel-select v-show="false"></hotel-select>
        </a-form-item>
      </div>
      <a-table bordered :data-source="data" :pagination="false" rowKey="id">
        <a-table-column
          key="parentName"
          title="集团名称"
          data-index="parentName"
        />
        <a-table-column
          key="expireTime"
          title="到期时间"
          data-index="expireTime"
        >
          <template slot-scope="record">
            <span>
              {{ record | formatDate }}
            </span>
          </template>
        </a-table-column>
        <a-table-column key="remark" title="备注" data-index="remark" />
        <a-table-column key="status" title="商城授权状态">
          <template slot-scope="record">
            <span>
              <a-switch :checked="record.status" @click="onChange(record)" />
            </span>
          </template>
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="edit(record)">编辑</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        show-quick-jumper
        :page-size-options="pageSizeOptions"
        :showSizeChanger="true"
        :current="page"
        :total="total"
        :show-total="(total) => `共 ${total} 条数据`"
        :page-size="limit"
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
      <!-- 编辑 -->
      <a-modal
        v-model="visible"
        title="编辑"
        okText="确定"
        cancelText="取消"
        @ok="onSubmit"
      >
        <a-form-model :model="editData" ref="ruleForm" :rules="rules">
          <div class="form_model">
            <a-form-model-item label="集团名称">
              <span>{{ editData.parentName }}</span>
            </a-form-model-item>
            <a-form-model-item label="到期时间" prop="expireTime">
              <a-date-picker
                format="YYYY-MM-DD HH:mm:ss"
                :disabledDate="disabledDate"
                :defaultValue="
                  moment(editData.expireTime, 'YYYY-MM-DD HH:mm:ss')
                "
                :show-time="{
                  defaultValue: moment('00:00:00', 'HH:mm:ss')
                }"
                @change="checkDate"
              />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-input
                v-model="editData.remark"
                type="textarea"
                ref="descInputValue"
                @keyup="descpNum"
              />
              <span class="descNum">{{ descNum }}/200</span>
            </a-form-model-item>
          </div>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getBlocList,
  updateBlocList,
  updateBlocListState
} from '../api/blocImpower.js'
import local from '../utils/local.js'
export default {
  inject: ['reload'],
  data() {
    this.dateFormat = 'YYYY-MM-DD'
    return {
      editValue: '',
      tenantArray: [],
      // 选择集团id
      tenantId: '',
      params: {},
      data: [],
      // 新增
      insertVisible: false,
      insertData: {},
      // 编辑数据对象
      editData: {},
      rules: {
        expireTime: [
          {
            required: true,
            message: '请选择到期时间',
            trigger: 'change'
          }
        ],
        remark: [
          {
            min: 0,
            max: 200,
            message: '长度限制200个字符',
            trigger: 'blur'
          }
        ]
      },
      title: '',
      visible: false,
      // 文本域动态长度
      descNum: 0,
      // 分页参数
      page: 1,
      limit: 10,
      total: 10,
      pageSizeOptions: ['5', '10', '20', '30', '50'],
      isAdmin: true
    }
  },
  created() {
    // eslint-disable-next-line no-unneeded-ternary
    this.isAdmin = local.get('userName') === 'admin' ? true : false
  },
  mounted() {
    if (!this.$store.state.spinning) {
      // console.log(this.$store.state.tenantArray)
      this.getParams()
    }
  },
  methods: {
    getParams() {
      this.$store.state.tenantArray.forEach(item => {
        this.tenantArray.push(item.code)
      })
      this.tenantId = this.$store.state.blocValue
        ? [this.$store.state.blocValue]
        : this.tenantArray
      this.params = {
        parentIdList: this.tenantId,
        limit: this.limit,
        page: this.page,
        mallTypeString: 'BLOC'
      }
      this.getList(this.params)
    },
    // 获取集团授权列表
    async getList(obj) {
      const data = await getBlocList(obj)
      if (data.code === 0) {
        this.data = data.data.records
        this.total = data.data.total
      }
    },
    getBlocValue(value) {
      value = value ? [value] : this.tenantArray
      this.params.parentIdList = value
      this.getList(this.params)
    },
    moment,
    checkDate(value) {
      this.editData.expireTime = value
    },
    disabledDate(current) {
      return current && current < moment().endOf('day')
    },
    // 分页
    onPageChange(pageNumber, limit) {
      this.page = pageNumber
      this.params.page = pageNumber
      this.getList(this.params)
    },
    onShowSizeChange(pageNumber, limit) {
      this.limit = limit
      this.params.limit = limit
      this.getList(this.params)
    },
    // 修改状态
    onChange(r) {
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '是否确认商城修改状态？',
        okType: 'danger',
        async onOk() {
          const data = await updateBlocListState({
            mallId: r.id,
            status: !r.status
          })
          if (data.code === 0) {
            self.$message.success(data.message)
            self.visible = false
            self.getList(self.params)
          } else {
            self.$message.error(data.message)
          }
        }
      })
    },
    // 备注文字数量计算
    descpNum() {
      this.descNum =
        this.$refs.descInputValue.value === null
          ? 0
          : this.$refs.descInputValue.value.length
    },
    // 编辑
    edit(r) {
      this.title = '编辑'
      this.visible = true
      r.expireTime = this.$options.filters.formatDate(r.expireTime)
      this.editData = JSON.parse(JSON.stringify(r))
      this.descNum = r.remark !== null ? r.remark.toString().length : 0
    },
    // 编辑
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.editData.expireTime = new Date(
            this.editData.expireTime
          ).getTime()
          const data = await updateBlocList(this.editData)
          if (data.code === 0) {
            this.visible = false
            this.getList(this.params)
            this.$message.success(data.message)
          } else {
            this.$message.error(data.message)
          }
        } else {
          return false
        }
      })
    }
  },
  // 时间戳转时间
  filters: {
    formatDate: function(value) {
      let date = new Date(value)
      date = moment(date).format('YYYY-MM-DD HH:mm:ss')
      return date
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 90%;
  .title {
    font-size: 18px;
    padding-bottom: 18px;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    height: 100%;
    button {
      margin-left: 0.8rem;
    }
    .ant-form-item {
      margin: 0;
    }
    .ant-select {
      height: 32px;
      padding-right: 1rem;
    }
  }
}

/deep/.ant-modal-content {
  .ant-calendar-picker {
    width: 200px;
  }
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}
</style>
