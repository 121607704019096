<template>
  <div style="height: 95%">
    <div class="content">
      <div class="action">
        <a-button type="primary" @click="clickTitle('insert', search.parentId)">
          新增
        </a-button>
      </div>
      <a-table
        bordered
        :data-source="goodsData"
        :pagination="false"
        rowKey="goodsId"
        :scroll="{ x: 1200, y: 500 }"
      >
        <a-table-column
          key="categoryName"
          title="商品分类"
          data-index="categoryName"
        />
        <a-table-column key="name" title="商品名称" data-index="name" />
        <a-table-column
          key="imageUrlsList"
          title="图片"
          data-index="imageUrlsList"
        >
          <template slot-scope="record">
            <div>
              <img
                alt="example"
                style="width: 60px; height: 50px"
                :src="record[0]"
                v-if="record"
              />
              <span v-if="!record">--</span>
            </div>
          </template>
        </a-table-column>
        <a-table-column
          key="specification"
          title="规格"
          data-index="specification"
        />
        <a-table-column key="costPrice" title="成本价" data-index="costPrice">
          <template slot-scope="record"> {{ record }} 元 </template>
        </a-table-column>
        <a-table-column key="basePrice" title="销售价" data-index="basePrice">
          <template slot-scope="record"> {{ record }} 元 </template>
        </a-table-column>
        <a-table-column
          key="discountPrice"
          title="折扣价"
          data-index="discountPrice"
        >
          <template slot-scope="text, record">
            {{ record.discountPrice }}
            <span v-if="record.buyTypeString === 'CASH'">元</span>
            <span v-else>分 </span>
          </template></a-table-column
        >
        <a-table-column
          key="saleAmount"
          title="已售数量"
          data-index="saleAmount"
        />
        <a-table-column key="totalNum" title="库存数量" data-index="totalNum" />
        <a-table-column
          key="qrCodeUrl"
          title="商品二维码"
          data-index="qrCodeUrl"
        >
          <template slot-scope="record">
            <span @click="lookOver(record)" style="cursor: pointer">查看</span>
            <a-modal v-model="lookOverVis" title="商品二维码" class="imgSrc">
              <img :src="tempImg" class="downloadImg" />
            </a-modal>
          </template>
        </a-table-column>
        <a-table-column key="status" title="状态" data-index="status">
          <template slot-scope="record">
            {{ record ? '正常' : '已下架' }}
          </template>
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="clickTitle('edit', record)">编辑</a>
              <a-divider type="vertical" />
              <a @click="inShelf(record)" v-if="!record.status">上架</a>
              <a @click="inShelf(record)" v-if="record.status">下架</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        show-quick-jumper
        :page-size-options="pageSizeOptions"
        :showSizeChanger="true"
        :current="page"
        :total="total"
        :show-total="(total) => `共 ${total} 条数据`"
        :page-size="limit"
        @change="onChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { getBlocGoodsList, changeBlocGoodsStatus } from '../../../api/goods.js'
export default {
  inject: ['reload'],
  props: {
    search: {
      type: Object
    }
  },
  data() {
    return {
      goodsData: [],
      data1: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          }
        ],
        desc: [
          {
            min: 0,
            max: 5,
            message: '长度限制200个字符',
            trigger: 'blur'
          }
        ]
      },
      visible: false,
      // 分页参数
      page: 1,
      pageSizeOptions: ['5', '10', '20', '30', '50'],
      limit: 5,
      total: 20,
      tempImg: '',
      lookOverVis: false,
      params: {
        limit: '',
        page: '',
        parentId: '',
        categoryId: '',
        goodsName: '',
        goodsStatus: ''
      }
    }
  },
  created() {
    this.params.page = this.page
    this.params.limit = this.limit
    this.params.parentId = this.search.parentId
    this.params.categoryId = this.search.categoryId
    this.params.goodsName = this.search.goodsName
    this.params.goodsStatus = this.search.goodsStatus
    this.getList(this.params)
    // this.getGoodsList(
    //   this.limit,
    //   this.page,
    //   this.search.parentId,
    //   this.search.categoryId,
    //   this.search.goodsName,
    //   this.search.goodsStatus
    // )
  },
  methods: {
    // 获取商品列表
    // async getGoodsList(
    //   limit,
    //   page,
    //   parentId,
    //   categoryId,
    //   goodsName,
    //   goodsStatus
    // ) {
    //   const data = await getBlocGoodsList({
    //     limit,
    //     page,
    //     parentId,
    //     categoryId,
    //     goodsName,
    //     goodsStatus
    //   })
    //   this.total = data.data.total
    //   this.goodsData = data.data.records
    // },
    async getList(params) {
      const data = await getBlocGoodsList(params)
      this.total = data.data.total
      this.goodsData = data.data.records === null ? [] : data.data.records
    },
    // 改变商品状态
    inShelf(e) {
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '是否确认修改商品状态？',
        okType: 'danger',
        async onOk() {
          const data = await changeBlocGoodsStatus({
            goodsId: e.goodsId,
            status: !e.status
          })
          data.code === 0
            ? self.$router.go(0)
            : self.$message.error(data.showMessage)
        }
      })
    },
    lookOver(img) {
      this.tempImg = img
      this.lookOverVis = true
    },
    downloadFileName(img) {
      window.location.href = img
    },
    // 分页
    onChange(pageNumber, limit) {
      this.page = pageNumber
      this.params.page = pageNumber
      // this.getGoodsList(
      //   this.limit,
      //   this.page,
      //   this.search.parentId,
      //   this.search.categoryId,
      //   this.search.goodsName,
      //   this.search.goodsStatus
      // )
      this.getList(this.params)
    },
    onShowSizeChange(pageNumber, limit) {
      this.limit = limit
      this.page = 1
      this.params.page = 1
      this.params.limit = limit
      // this.getGoodsList(
      //   this.limit,
      //   1,
      //   this.search.parentId,
      //   this.search.categoryId,
      //   this.search.goodsName,
      //   this.search.goodsStatus
      // )
      this.getList(this.params)
    },
    // 新增\编辑
    clickTitle(name, params) {
      if (name === 'edit') {
        this.$router.push({
          name: 'edit',
          params: { info: params }
        })
        params.parentId = this.search.parentId
      } else {
        this.$router.push({
          name: 'insert',
          params: { parentId: params }
        })
      }
    }
  },
  computed: {
    getSearch() {
      return this.search
    }
  },
  watch: {
    search: {
      deep: true,
      handler: function(newval, oldval) {
        // const params = {
        //   limit: this.limit,
        //   page: this.page,
        //   parentId: newval.parentId,
        //   goodsName: newval.goodsName,
        //   categoryId: newval.categoryId,
        //   goodsStatus: newval.goodsStatus
        // }
        this.params.parentId = newval.parentId
        this.params.goodsName = newval.goodsName
        this.params.categoryId = newval.categoryId
        this.params.goodsStatus = newval.goodsStatus
        if (this.params.categoryId === 'all') {
          this.params.categoryId = ''
        }
        if (newval.goodsStatus === 'all') {
          this.params.goodsStatus = ''
        }
        // if (newval.goodsStatus === 'all' && newval.categoryId === 'all') {
        //   delete params.categoryId
        //   delete params.goodsStatus
        // }
        this.getList(this.params)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.imgSrc /deep/.ant-modal-footer {
  display: flex;
  align-items: center;
  display: none;
}
.content {
  background-color: #fff;
  padding-bottom: 2.4rem;
  height: 95%;
  .action {
    display: flex;
    justify-content: space-between;
    padding: 1.4rem 1rem;
    .ant-form-item {
      margin: 0;
    }
    .ant-select {
      width: 18rem;
      height: 32px;
      padding-right: 1rem;
    }
  }
}
.qrCodeUrl {
  width: 54px;
  &:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 200px;
  }
}
// .ant-pagination {
//   /deep/.ant-select-selection--single {
//     width: 10rem;
//   }
// }

/deep/.ant-table td {
  white-space: nowrap;
}
/deep/.ant-table-thead > tr > th,
/deep/.ant-table-tbody > tr > td {
  width: 92px !important;
}
.ant-table {
  table-layout: fixed;
}
/deep/.ant-table-tbody > tr > td {
  max-width: 200px;
  min-width: 70px;
  /*text-align: center !important;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
