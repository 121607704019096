<template>
  <div class="index_wrapper">
    <div class="title">商城分类配置</div>
    <div class="content">
      <div class="action">
        <a-button type="primary" @click="insert"> 新增 </a-button>
        <a-form-item
          label="酒店名称"
          style="display: flex; justify-content: flex-end"
        >
          <hotelSelect @selectChange="getHotelValue"></hotelSelect>
        </a-form-item>
      </div>
      <a-table bordered :data-source="data" :pagination="false" rowKey="id">
        <a-table-column key="sortWeight" title="序号" data-index="sortWeight" />
        <a-table-column
          key="parentName"
          title="酒店名称"
          data-index="parentName"
        />
        <a-table-column key="mallName" title="商城名称" data-index="mallName" />
        <a-table-column
          key="categoryName"
          title="分类名称"
          data-index="categoryName"
        />
        <a-table-column key="remark" title="备注" data-index="remark" />
        <a-table-column key="status" title="状态">
          <template slot-scope="record">
            <span>
              <a-switch :checked="record.status" @change="onChange(record)" />
            </span>
          </template>
        </a-table-column>
        <a-table-column key="action" title="操作">
          <template slot-scope="record">
            <span>
              <a @click="edit(record)">编辑</a>
              <a-divider type="vertical" />
              <a @click="Delete(record.id)">删除</a>
            </span>
          </template>
        </a-table-column>
      </a-table>
      <!-- 编辑 -->
      <a-modal
        v-model="visible"
        :title="title"
        okText="确定"
        cancelText="取消"
        @ok="onSubmit"
      >
        <a-form-model :model="editData" ref="ruleForm" :rules="rules">
          <div class="form_model">
            <a-form-model-item
              style="margin-bottom: 6px !important"
              label="酒店名称"
            >
              <span>{{
                title === '新增' ? data[0].mallName : editData.parentName
              }}</span>
            </a-form-model-item>
            <a-form-model-item
              style="margin-bottom: 6px !important"
              label="商城名称"
            >
              <span>{{
                title === '新增' ? data[0].parentName : editData.mallName
              }}</span>
            </a-form-model-item>
            <a-form-model-item label="排序" prop="sortWeight">
              <a-input
                type="number"
                v-model="editData.sortWeight"
                placeholder="必填"
              />
            </a-form-model-item>
            <a-form-model-item label="分类名称" prop="categoryName">
              <a-input v-model="editData.categoryName" placeholder="必填" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-input v-model="editData.remark" type="textarea" />
            </a-form-model-item>
          </div>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  getBlocClassifyList,
  getBlocClassifyState,
  editBlocClassify,
  insertBlocClassify,
  deleteBlocClassify
} from '../../../api/blocImpower.js'
import { login } from '../../../api/login.js'
import local from '../../../utils/local.js'
export default {
  inject: ['reload'],
  data() {
    const validateRange = (rule, value, callback) => {
      if (this.editData.sortWeight < 0 || this.editData.sortWeight > 99) {
        callback(new Error('序号限制范围为0-99的数字'))
      } else {
        callback()
      }
    }
    return {
      hotelId: '',
      value: '',
      hotelList: [],
      data: [],
      Id: '',
      title: '',
      editData: {},
      rules: {
        categoryName: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur'
          },
          {
            max: 4,
            message: '最多输入4位字符数',
            trigger: 'blur'
          },
          {
            pattern: /^[^\s]*$/,
            message: '禁止输入空格',
            trigger: 'blur'
          }
        ],
        remark: [
          {
            min: 0,
            max: 200,
            message: '长度限制200个字符',
            trigger: 'blur'
          }
        ],
        sortWeight: [
          {
            validator: validateRange,
            message: '序号限制范围为0-99的数字',
            trigger: 'blur'
          }
        ]
      },
      visible: false
    }
  },
  created() {
    this.hotelId = local.get('hotelId')
    this.getClassifyList(this.hotelId)
  },
  methods: {
    // 获取商品分类列表
    async getClassifyList(temp) {
      const data = await getBlocClassifyList({
        parentId: temp,
        mallTypeString: 'HOTEL'
      })
      data.code === 0 ? (this.data = data.data) : ''
    },
    getHotelValue(v) {
      this.hotelId = v
      // this.getClassifyList(v)
    },
    checkDate(date, dateString) {
      this.startTime = moment(dateString[0]).format('YYYY-MM-DD HH:mm:ss')
      this.endTime = moment(dateString[1]).format('YYYY-MM-DD HH:mm:ss')
    },
    selectChange(v) {
      this.value = v
      this.getClassifyList(v)
    },
    onChange(r) {
      const self = this
      this.$confirm({
        title: '确认提示',
        content: '是否确认修改状态？',
        okType: 'danger',
        async onOk() {
          const data = await getBlocClassifyState({
            categoryId: r.id,
            status: !r.status
          })
          let code = ''
          self.hotelList.forEach(item => {
            item.hotelId === self.value ? (code = item.hotelId) : ''
          })
          data.code === 0
            ? self.getClassifyList(self.hotelId)
            : self.$message.error(data.message)
        }
      })
    },
    // 新增
    insert() {
      this.visible = true
      this.editData = {}
      this.title = '新增'
    },
    // 编辑
    edit(r) {
      this.visible = true
      this.title = '编辑'
      this.editData = JSON.parse(JSON.stringify(r))
    },
    // 删除
    async Delete (r) {
      const self = this
      var id = this.value
      this.$confirm({
        title: '确认提示',
        content: '是否确认删除此数据？',
        okType: 'danger',
        async onOk() {
          const data = await deleteBlocClassify({ categoryId: r })
          if (data.code === 0) {
            self.$message.success(data.message)
            self.getClassifyList(self.hotelId)
          } else {
            self.$message.error(data.message)
          }
        }
      })
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        let code = ''
        this.hotelList.forEach(item => {
          item.hotelId === this.value ? (code = item.hotelId) : ''
        })
        if (valid && this.title === '编辑') {
          const data = await editBlocClassify(this.editData)
          data.code === 0
            ? this.getClassifyList(this.hotelId)
            : this.$message.error(data.message)
          this.visible = false
        } else if (valid && this.title === '新增') {
          this.editData.status = true
          this.editData.mallId = this.data[0].mallId
          this.editData.buyTypeString = 'CASH'
          const data = await insertBlocClassify(this.editData)
          if (data.code === 0) {
            this.getClassifyList(this.hotelId)
            this.$message.success(data.message)
          } else {
            this.$message.error(data.message)
          }
          this.visible = false
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor: #4196ff;
.index_wrapper {
  text-align: left;
  padding: 2.2rem 1.6rem 0 1.6rem;
  width: 100%;
  height: 90%;
  .title {
    font-size: 18px;
    padding-bottom: 1.8rem;
  }
  .content {
    background-color: #fff;
    padding-bottom: 2.4rem;
    height: 100%;
    .action {
      display: flex;
      justify-content: space-between;
      padding: 1.4rem 1rem;
      .ant-form-item {
        margin: 0;
        display: flex;
        width: 24%;
      }
      .ant-select {
        height: 32px;
        padding-right: 1rem;
      }
    }
  }
}

/deep/.ant-modal {
  width: 600px !important;
}
/deep/.ant-modal-content {
  // width: 70rem;
  /deep/.ant-btn-primary {
    background-color: @baseColor;
    border-color: @baseColor;
    &:hover {
      color: #fff !important;
    }
  }
  /deep/ .ant-btn:hover {
    color: @baseColor;
    border-color: @baseColor;
  }
}
.form_model .ant-form-item .ant-form-item-control-wrapper {
  line-height: 40px;
}
/deep/ .ant-form-item-control .ant-select-selection--single {
  width: 170px;
}
</style>
